import * as runtime from '$lib/paraglide/runtime.js';
import { createI18n } from '@inlang/paraglide-sveltekit';

// src/lib/i18n.js
export const i18n = createI18n(runtime, {
	defaultLanguageTag: 'en',
	// defaultLanguageTag: import.meta.env.VITE_ADAPTER === 'capacitor' ? undefined : 'en',
	prefixDefaultLanguage: 'always',
	// prefixDefaultLanguage: import.meta.env.VITE_ADAPTER === 'capacitor' ? 'never' : 'always',
	exclude: [/^\/api\//],
});
